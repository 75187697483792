import React, { useEffect, useState } from "react"
import "../../layout/Sidebar.css"
import "../Sidebar.css"
import logo from "../../assets/sidebarlogo.svg"
import menuicon from "../../assets/menuicon.svg"
import notification from "../../assets/notification.svg"
import profile from "../../assets/profile.svg"
import AuthService from "../../services/Auth"
import { getUserSession } from "../../util/SessionStorage"
import "./css/header.css"
import ConfirmBox from "./confirmBox"

const Header = (props) => {
  let header = ""
  if (props.heading) {
    header = props.heading
  }

  const [username, setusername] = useState()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const user = getUserSession()
    if (user) {
      setusername(user.data.user.name)
    }
  }, [])

  const toggleDropdown = () => {
    console.log("Toggle Dropdown called")
    setIsDropdownOpen((prevState) => !prevState)
  }

  const openPopUp = () => {
    setShowPopup(true)
  }

  const closePopUp = () => {
    setShowPopup(false)
  }

  const handleLogout = () => {
    AuthService.logout()
    window.location.reload()
  }
  return (
    <div className="sidemainheader">
      <div className="sideHeader">
        <div className="sidebarlogo ">
          <img src={logo} alt="" id="sidebarlogoimg" />
          <h1 className="sidelogoName">Tes Markets</h1>
        </div>
      </div>
      <div className="mainHeader">
        <div className="headermenuicon">
          <img src={menuicon} alt="" />
          <h1>{header}</h1>
        </div>

        <div className="mainHeader2">
          {/* <span className='darkmodetext'>Dark Mode</span>
                <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                </label> */}
          <div className="rightsideheader">
            <a href="#" class="notification">
              <img className="notificationimg" src={notification} alt="" />
              <span class="badge">34</span>
            </a>
            <div className="line"></div>
            <div className="profileheader">
              <div>
                <h1>{username}</h1>
                <span>@uxDesing</span>
              </div>
              <div className="dropdown">
                <img
                  className="profileimg"
                  src={profile}
                  alt=""
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div className="dropdown-content">
                    {/* Dropdown content goes here */}
                    <a href="#">
                      {" "}
                      <p>Profile</p>{" "}
                    </a>
                    <a href="#" onClick={openPopUp}>
                      {" "}
                      <p>Logout</p>{" "}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <ConfirmBox
            type="Logout"
            isopen={showPopup}
            isclose={closePopUp}
            handleLogic={handleLogout}
          />
        )}
      </div>
    </div>
  )
}

export default Header
