import axios from "axios"
import { Constants } from "../util/Constant"

const getUsers = async (token) => {
  try {
    const response = await axios.get(
      Constants.SERVER.API_URL +
        Constants.ROUTES.ADMIN +
        Constants.ROUTES.USERS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const walletRecharge = async (token, recharge, id, payment_transaction_id) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL +
        Constants.ROUTES.ADMIN +
        Constants.ROUTES.USERS +
        Constants.ROUTES.RECHARGE_WALLET,
      {
        id: id,
        wallet_amount: recharge,
        payment_transaction_id: payment_transaction_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const getRechargeRequests = async (token) => {
  try {
    const response = await axios.get(
      Constants.SERVER.API_URL + Constants.ROUTES.USER_PAYMENT,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const getUserTransactions = async (token, userId) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL +
        Constants.ROUTES.USER_PAYMENT +
        Constants.ROUTES.FILTER,
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const UserServices = {
  getUsers,
  walletRecharge,
  getRechargeRequests,
  getUserTransactions,
}

export default UserServices
