export const getUserSession = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  if (user) {
    return user
  }
}

export const setUserSession = (response) => {
  return localStorage.setItem("user", JSON.stringify(response.data))
}

export const deleteUserSession = () => {
  return localStorage.removeItem("user")
}
