import axios from "axios"
import { Constants } from "../util/Constant"

const login = async (username, password) => {
  const response = await axios.post(
    Constants.SERVER.API_URL + Constants.ROUTES.ADMIN + Constants.ROUTES.LOGIN,
    {
      username: username,
      password: password,
    }
  )
  if (response.status == Constants.STATUS.OK) {
    localStorage.setItem("user", JSON.stringify(response.data))
  }
  return response.data
}

const logout = () => {
  localStorage.removeItem("user")
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

const AuthService = {
  login,
  logout,
  getCurrentUser,
}

export default AuthService
