import React from "react"
import "./css/confirmbox.css"

export default function ConfirmBox({ type, isopen, isclose, handleLogic }) {
  const handleConfirm = () => {
    handleLogic()
    isclose()
  }

  const handleCancel = () => {
    isclose()
  }

  return (
    <div>
      {isopen && (
        <div className="popup-wrapper-confirm">
          <div className="popup-confirm">
            <h3>Are You Sure To {type}?</h3>
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  )
}
