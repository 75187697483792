import React from "react"
import { NavLink } from "react-router-dom"
import "../../layout/Sidebar.css"
import { AutoAwesomeMosaic, People, Equalizer } from "@mui/icons-material"

const Sidebar = ({ screenName }) => {
  return (
    <div className="sidebarmain">
      <div className="sidenav">
        <div className="navmenu">
          <NavLink
            to="/dashboard"
            className={
              screenName == "Dashboard" ? "navitemSelected" : "navitem"
            }
          >
            <AutoAwesomeMosaic style={{ marginLeft: "18%" }} />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/users"
            className={screenName == "AllUsers" ? "navitemSelected" : "navitem"}
          >
            <People style={{ marginLeft: "18%" }} />
            <span>Users</span>
          </NavLink>
          <NavLink
            to="/custom-stocks"
            className={
              screenName == "CustomStocks" ? "navitemSelected" : "navitem"
            }
          >
            <Equalizer style={{ marginLeft: "18%" }} />
            <span>Custom Stocks</span>
          </NavLink>
        </div>
      </div>

      <div className="sidefooter">
        <h1>Tes Markets Platform</h1>
        <span> &copy; 2024 All Rights Reserved</span>
      </div>
    </div>
  )
}

export default Sidebar
