import React, { useEffect, useState } from "react"
import Header from "./components/header"
import Sidebar from "./components/sidebar"
import "./components/css/allusers.css"
import UserServices from "../services/Users"
import { Alert } from "@mui/material"
import { getUserSession } from "../util/SessionStorage"
import { Constants } from "../util/Constant"
import ConfirmBox from "./components/confirmBox"

export default function AllUsers() {
  const [darkmode, setDarkmode] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showTrasactionRequestTable, setShowTrasactionRequestTable] =
    useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [rechargeAmount, setRechargeAmount] = useState("")
  const [walletUpdate, setWalletUpdate] = useState(false)
  const [userData, setuserData] = useState([])
  const [requestList, setRequestList] = useState([])
  const [userTransactions, setuserTransactions] = useState([])
  const [showconfirmPopup, setshowconfirmPopup] = useState(false)
  const [selectedTransaction, setselectedTransaction] = useState()

  // fetch all users data
  useEffect(() => {
    async function getUsers() {
      const adminUser = getUserSession()
      if (adminUser) {
        try {
          const result = await UserServices.getUsers(adminUser.data.token)
          setuserData(result.data.users)
          setWalletUpdate(false)
        } catch (error) {
          console.log(error)
        }
      }
    }
    getUsers()
  }, [walletUpdate])

  // fetch all users transactions request list at button click
  useEffect(() => {
    async function fetchRequests() {
      const adminUser = getUserSession()
      if (adminUser) {
        try {
          const result = await UserServices.getRechargeRequests(
            adminUser.data.token
          )
          setRequestList(result.data.transactions)
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (showTrasactionRequestTable) {
      fetchRequests()
    }
  }, [showTrasactionRequestTable])

  // fetch single user's all transacations at button click
  const fetchUserTrancations = async (userId) => {
    const adminUser = getUserSession()
    if (adminUser) {
      try {
        const result = await UserServices.getUserTransactions(
          adminUser.data.token,
          userId
        )
        setuserTransactions(result.data.transactions)

        console.log(userTransactions)
      } catch (error) {
        console.log(error)
      }
    }
  }

  // handle functions

  const handleRecharge = (user) => {
    fetchUserTrancations(user._id)
    setSelectedUser(user)
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setSelectedUser(null)
    setRechargeAmount("")
  }

  const handleCheckRequests = () => {
    setShowTrasactionRequestTable(true)
  }

  const handleCloseRequestPopup = () => {
    setShowTrasactionRequestTable(false)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based
    const year = date.getFullYear()
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`
  }

  const handleConfirmRecharge = (rechargeAmount, transaction_id) => {
    openConfirmPopUp()

    setselectedTransaction({ rechargeAmount, transaction_id })
  }

  const doRecharge = async () => {
    const adminUser = JSON.parse(localStorage.getItem("user"))

    try {
      if (adminUser) {
        const result = await UserServices.walletRecharge(
          adminUser.data.token,
          selectedTransaction.rechargeAmount,
          selectedUser._id,
          selectedTransaction.transaction_id
        )
        console.log("handleConfirmRecharge debug", result)
        if (result.data.updated) {
          setWalletUpdate(true)
          alert(Constants.MESSAGE.RECHARGESUCCESS)
        } else {
          alert()
        }
        handleClosePopup(Constants.MESSAGE.RECHARGEUNSUCCESS)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //pop up
  const openConfirmPopUp = () => {
    setshowconfirmPopup(true)
  }

  const closeConfirmPopUp = () => {
    setshowconfirmPopup(false)
  }

  return (
    <body className={darkmode ? "dark_mode" : "light_mode"}>
      <Header heading="All Users"></Header>
      <div className="divisioncomp">
        <Sidebar screenName="Users" />
        <div className="user-table-container">
          <div className="user-table">
            {!showTrasactionRequestTable ? (
              <div>
                <div className="wallet-recharge-request">
                  <button onClick={handleCheckRequests}>ALL PAYMENTS</button>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact</th>
                      <th>Wallet Amount</th>
                      <th>Wallet</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.map((user) => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.wallet_amount}</td>
                        <td>
                          <button
                            className="recharge-btn"
                            onClick={() => handleRecharge(user)}
                          >
                            Transactions
                          </button>
                          <span className="tooltip">Recharge Wallet</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <div className="request-header">
                  <h2>Payment History</h2>
                  <button
                    className="request-close-btn"
                    onClick={handleCloseRequestPopup}
                  >
                    Close
                  </button>
                </div>
                <table className="">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact</th>
                      <th>Amount</th>
                      <th>Transaction ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      {/* <th>File</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {requestList.map((request) => (
                      <tr key={request._id}>
                        <td>{request.user.name}</td>
                        <td>{request.user.email}</td>
                        <td>{request.user.phone}</td>
                        <td>{request.amount}</td>
                        <td>{request.transaction_id}</td>
                        <td>{formatDate(request.date)}</td>
                        <td>
                          {request.status === Constants.STATUS.ACTIVE
                            ? "Pending"
                            : "Completed"}
                        </td>
                        {/* <td>{request.file}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>{selectedUser.name} Available Transactions</h2>
            {Array.isArray(userTransactions) &&
              userTransactions.map((transaction) => {
                if (transaction.status === Constants.STATUS.ACTIVE) {
                  return (
                    <div className="transaction-card" key={transaction._id}>
                      <p>
                        <strong>Date:</strong> {formatDate(transaction.date)}
                      </p>
                      <p>
                        <strong>Status:</strong> Active
                      </p>
                      <p>
                        <strong>Transaction ID:</strong>{" "}
                        {transaction.transaction_id}
                      </p>
                      <p>
                        <strong>Amount:</strong> {transaction.amount}
                      </p>
                      <button
                        className="recharge-btn"
                        onClick={() =>
                          handleConfirmRecharge(
                            transaction.amount,
                            transaction.transaction_id
                          )
                        }
                      >
                        Recharge
                      </button>
                    </div>
                  )
                }
              })}
            {
              <button className="cancel-btn" onClick={handleClosePopup}>
                Cancel
              </button>
            }
          </div>
        </div>
      )}
      {showconfirmPopup && (
        <ConfirmBox
          type="Confirm Recharge"
          isopen={showconfirmPopup}
          isclose={closeConfirmPopUp}
          handleLogic={doRecharge}
        />
      )}
    </body>
  )
}
