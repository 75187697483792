export const Constants = {
  SERVER: {
    API_URL: "http://16.170.249.131:3000/api/v1",
    // API_URL: "http://localhost:7777/api/v1",
  },
  STATUS: {
    OK: 200,
    ACTIVE: 1,
  },
  ROUTES: {
    ADMIN: "/admin",
    LOGIN: "/login",
    USERS: "/users",
    RECHARGE_WALLET: "/recharge-wallet",
    USER_PAYMENT: "/user-payment",
    FILTER: "/filter",
  },
  MESSAGE: {
    RECHARGESUCCESS: "Recharge Successfull",
    RECHARGEUNSUCCESS: "Recharge Unsuccessfull , Please Try Again Later ",
  },
}
