import React,{useState} from 'react'
import Header from './components/header'
import Sidebar from './components/sidebar'
import ComingSoon from './components/comingSoon'

export default function CustomStocks() {
        const [darkmode, setDarkmode] = useState(false);
  return (
    <>
            <body className={darkmode ? 'dark_mode' : 'light_mode'}>
                <Header heading= 'Custom Stocks'></Header>
                <div className='divisioncomp'>
                   <Sidebar screenName = 'Custom Stocks'/>
                   <ComingSoon/>
                </div>
            </body>

        </>
  )
}
